var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"mb-3"},[_vm._v("Clientes")])]),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){_vm.createClienteModal = true;}}},[_vm._v("Crear cliente")])],1)],1),_c('b-table',{attrs:{"items":_vm.clientes,"fields":_vm.fields,"responsive":"","hover":"","no-border-collapse":"","show-empty":""},on:{"row-clicked":_vm.openRow},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v("No hay datos para mostrar")])]},proxy:true},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.createdAt).format('DD MMMM YYYY, HH:mmA')))])]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA')))])]}}])}),_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('b-pagination',{staticClass:"mb-0",attrs:{"total-rows":_vm.paginationTotal,"per-page":_vm.paginationLimit},on:{"input":_vm.getClientes},model:{value:(_vm.paginationPage),callback:function ($$v) {_vm.paginationPage=$$v},expression:"paginationPage"}}),(_vm.loadingData)?_c('b-spinner',{staticClass:"ml-3",attrs:{"variant":"primary","small":""}}):_vm._e()],1),_c('b-modal',{attrs:{"hide-footer":""},model:{value:(_vm.createClienteModal),callback:function ($$v) {_vm.createClienteModal=$$v},expression:"createClienteModal"}},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createCliente)}}},[_c('b-form-group',{attrs:{"label":"Nombre de cliente"}},[_c('validation-provider',{attrs:{"name":"nombre de cliente","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nombre de cliente","required":"","name":"name","state":errors[0] ? false : null},model:{value:(_vm.newClienteName),callback:function ($$v) {_vm.newClienteName=$$v},expression:"newClienteName"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"text-right mt-3"},[_c('b-button',{attrs:{"variant":"success","disabled":invalid || _vm.loadingData,"type":"submit"}},[_vm._v("Crear cliente")])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }