<template lang="pug">
b-container(fluid)
  b-row
    b-col
      h3.mb-3 Clientes
    b-col(cols="auto")
      b-button(variant="success", @click="createClienteModal = true;") Crear cliente
  b-table(
    :items="clientes",
    :fields="fields",
    responsive,
    hover,
    no-border-collapse,
    @row-clicked="openRow",
    show-empty
  )
    template(#empty)
      .text-center No hay datos para mostrar
    template(#cell(createdAt)="{item}")
      span {{ $moment(item.createdAt).format('DD MMMM YYYY, HH:mmA') }}
    template(#cell(updatedAt)="{item}")
      span {{ $moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA') }}
  .d-flex.align-items-center.mb-3
    b-pagination.mb-0(
      v-model="paginationPage",
      :total-rows="paginationTotal",
      :per-page="paginationLimit",
      @input="getClientes"
    )
    b-spinner.ml-3(variant="primary", small, v-if="loadingData")

  b-modal(v-model="createClienteModal", hide-footer)
    b-container
      b-row
        b-col(cols="12")
          validation-observer(v-slot="{invalid, handleSubmit}")
            b-form(@submit.prevent="handleSubmit(createCliente)")
              b-form-group(label="Nombre de cliente")
                validation-provider(
                  name="nombre de cliente",
                  rules="required|min:1",
                  v-slot="{ errors }"
                )
                  b-form-input(
                    placeholder="Nombre de cliente",
                    required,
                    name="name",
                    v-model="newClienteName",
                    :state="errors[0] ? false : null"
                  )
                  b-form-invalid-feedback {{ errors[0] }}

              .text-right.mt-3
                b-button(variant="success", :disabled="invalid || loadingData", type="submit") Crear cliente
</template>

<script>
import { createNamespacedHelpers, mapActions } from "vuex";
const clienteController = createNamespacedHelpers("cliente");

export default {
  data() {
    return {
      newClienteName: "",
      createClienteModal: false,
      fields: [
        {
          key: "cliente_id",
          label: "Id",
        },
        {
          key: "name",
          label: "Nombre Cliente",
        },
        {
          key: "updatedAt",
          label: "Actualizado",
        },
        {
          key: "createdAt",
          label: "Creado",
        },
      ],
    };
  },
  computed: {
    ...clienteController.mapState({
      clientes: (state) => state.clientes,
    }),
  },
  methods: {
    ...mapActions(["cliente/all", 'cliente/create']),
    getStatusVariant(status) {
      switch (status) {
        case "entrada":
          return "success";
        case "salida":
          return "secondary";
        default:
          return "light";
      }
    },
    getClientes() {
      this.loadingData = true;
      this["cliente/all"]({
        params: {
          page: this.paginationPage,
          limit: this.paginationLimit,
        },
        callback: (res) => {
          this.loadingData = false;

          if (res.success) {
            this.paginationTotal = res.total;
            this.paginationHasNextPage = res.hasNextPage;
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo los clientes. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    createCliente() {
      this.loadingData = true;
      this["cliente/create"]({
        name: this.newClienteName,
        callback: (res) => {
          this.loadingData = false;

          if (res.success) {
            this.$bvToast.toast(`Cliente creado con éxito.`, {
              title: "Creado",
              variant: "success",
            });

            this.createClienteModal = false;

            setTimeout(() => {
              this.$router.push({
                name: "Cliente",
                params: { cliente_id: res.resource.cliente_id },
              });
            }, 1500);
          } else {
            this.$bvToast.toast(
              `Ocurrió un error creando cliente. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    openRow(item) {
      this.$router.push({
        name: "Cliente",
        params: { cliente_id: item.cliente_id },
      });
    },
  },
  mounted() {
    this.getClientes();
  },
};
</script>

<style>
</style>